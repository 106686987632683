<template>
  <row v-if="articleList">
    <column
      xs="12"
      lg="9">
      <row>
        <!-- Top story -->
        <column
          xs="12"
          md="8">
          <teaser
            :teaser-element="articleList[0]"
            :show-main-category="false"
            teaser-type="extraextraLarge"
            image-size="big"
            eager/>
        </column>

        <!-- Most read list -->
        <column
          xs="12"
          md="4"
          class="order-1 order-md-0 most-read-list mb-3">
          <h4
            v-text="__('most.read')"
            class="ff-flama fs-lg fw-bold tt-uppercase pb-2 pl-2 list-header mb-0"></h4>
          <MostReadList/>
        </column>

        <!-- 2 large stories -->
        <column
          v-for="article in articleList.slice(1, 3)"
          :key="article.title"
          xs="12"
          sm="6"
          md="4">
          <teaser
            :teaser-element="article"
            image-size="small"
            eager/>
        </column>

        <!-- Sidekick ad on smaller screens -->
        <column
          xs="12"
          class="d-block d-lg-none mb-3 text-center">
          <ad slot-id="skyScraperMobile"/>
        </column>

        <!-- 4 large stories -->
        <column
          v-for="article in articleList.slice(3, 7)"
          :key="article.title"
          xs="12"
          sm="6"
          md="4">
          <teaser
            :teaser-element="article"
            image-size="small"
            eager/>
        </column>

        <CustomAdPlaceholder
          v-if="customAdPlaceholder"
          :info="customAdPlaceholder"/>

        <column
          xs="12"
          class="d-block mb-3 text-center">
          <ad slot-id="sponsoredPremiumContent"/>
        </column>

        <!-- MPU1 ad on smaller screens -->
        <column
          xs="12"
          class="d-block d-lg-none mb-3 text-center">
          <ad slot-id="mediumRectangleMobile"/>
        </column>

        <!-- 4 medium stories -->
        <column
          v-for="article in articleList.slice(7, 11)"
          :key="article.title"
          xs="12"
          md="6">
          <teaser
            :grid="[5, 7]"
            :teaser-element="article"
            teaser-type="medium"
            image-size="small"/>
        </column>
      </row>
    </column>

    <!-- Ads -->
    <column
      xs="3"
      class="d-none d-lg-block">
      <div class="mb-3 text-center">
        <ad slot-id="brandBanner"/>
      </div>
      <div class="mb-3 text-center">
        <ad slot-id="skyScraper"/>
      </div>
      <Sticky :top="top + 10">
        <div class="mb-3 text-center">
          <ad slot-id="mediumRectangle"/>
        </div>
        <!-- Epaper promo-->
        <PromoBox/>
      </Sticky>
    </column>
  </row>
</template>

<script>
import { MostReadList, PromoBox, CustomAdPlaceholder } from 'global-components';

export default {
  name: 'frontpage-top-block',
  components: {
    MostReadList,
    PromoBox,
    CustomAdPlaceholder
  },
  props: {
    articleList: {
      type: Array,
      required: true
    }
  },
  data() {
    const customAdPlaceholder = this.getCustomAdPlaceholder();
    return {
      top: 0,
      customAdPlaceholder
    };
  },
  beforeMount() {
    this.$root.$on('topBarHeight', (topBarHeight) => {
      this.top = topBarHeight;
    });
  },
  beforeDestroy() {
    this.$root.$off('topBarHeight');
  },
  methods: {
    getCustomAdPlaceholder() {
      return this.$store.state.edition.customAd;
    },
  },
};
</script>
