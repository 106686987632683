<template>
  <row v-if="articleList">
    <column lg="9">
      <row>
        <column
          v-for="article in articleList"
          :key="article.id"
          xs="12"
          md="6">
          <teaser
            :grid="[5, 7]"
            :teaser-element="article"
            image-size="small"
            teaser-type="medium"/>
        </column>
      </row>

      <div class="d-block d-lg-none text-center mb-3">
        <ad slot-id="mediumRectangleMobileSecond"/>
      </div>

      <TwoColumnSponsorContentAdRow/>

      <!-- Inbrief -->
      <InbriefPlaceholder/>

      <div class="d-block d-lg-none text-center mb-3">
        <ad slot-id="mediumRectangleMobileThird"/>
      </div>
    </column>
    <column
      md="3"
      class="d-none d-lg-block">
      <div class="mb-3">
        <ad slot-id="mediumRectangleSecond"/>
      </div>
      <div class="mb-3">
        <ad slot-id="mediumRectangleThird"/>
      </div>
    </column>
  </row>
</template>

<script>
import TwoColumnSponsorContentAdRow from './TwoColumnSponsorContentAdRow';
import { InbriefPlaceholder } from 'global-components';

export default {
  name: 'frontpage-mpu-block',
  components: {
    TwoColumnSponsorContentAdRow,
    InbriefPlaceholder,
  },
  props: {
    articleList: {
      type: Array,
      required: true
    }
  },
};
</script>
