<template>
  <div>
    <FishPriceTicker v-if="showFishPrice"/>
    <LatestNewsTicker
      v-if="shouldShowLatestNews"
      :ticker-header="__('latest.news')"
      :ticker-header-url="this.$store.state.publication.latestNewsUrl"
      scroll-speed="70"/>
    <div class="mb-3 text-center">
      <ad slot-id="leaderBoard"/>
    </div>
    <div class="wrapper text-center">
      <ad slot-id="magstripe"/>
    </div>
    <div class="d-flex justify-content-center">
      <div class="flex-block-col left d-xxl-block">
        <Sticky :top="top + 10">
          <ad
            slot-id="stickyLeft"/>
        </Sticky>
      </div>
      <div class="flex-block-col middle wrapper">
        <FrontpageTopBlock
          v-if="list[0]"
          :article-list="list[0]"/>
      </div>
      <div class="flex-block-col right d-xxl-block"></div>
    </div>
    <div class="wrapper">
      <row>
        <column
          xs="12"
          class="mb-3 mt-3 text-center">
          <ad slot-id="panorama"/>
        </column>
      </row>
    </div>
    <Placeholder/>
    <div class="mb-3 mt-3 text-center">
      <ad slot-id="parallax"/>
    </div>
    <div class="wrapper">
      <FrontpageMpuBlock
        v-if="list[1]"
        :article-list="list[1]"/>
    </div>

    <Placeholder/>

    <div class="wrapper">
      <EditionRegularBlock
        v-if="list[2]"
        :article-list="list[2]"/>
      <row>
        <column
          xs="12"
          class="mb-3 mt-3 text-center">
          <ad slot-id="billboard"/>
        </column>
      </row>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="list[3]"
        :article-list="list[3]"/>
      <row>
        <column
          xs="12"
          class="mb-3 mt-3 text-center">
          <ad slot-id="panoramaSecond"/>
        </column>
      </row>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="list[4]"
        :article-list="list[4]"/>
      <row>
        <column
          xs="12"
          class="mb-3 mt-3 text-center">
          <ad slot-id="billboardSecond"/>
        </column>
      </row>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="list[5]"
        :article-list="list[5]"/>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="list[6]"
        :article-list="list[6]"/>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="list[7]"
        :article-list="list[7]"/>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="list[8]"
        :article-list="list[8]"/>
    </div>
    <Placeholder/>
    <div class="wrapper">
      <EditionRegularBlock
        v-if="list[9]"
        :article-list="list[9]"/>
    </div>
    <Placeholder reset/>
  </div>
</template>
<script>
import {
  FrontpageTopBlock,
  FrontpageMpuBlock,
  EditionRegularBlock,
} from './page-segments/index';
import {
  Page,
  Placeholder,
  FishPriceTicker,
  LatestNewsTicker,
} from 'global-components';
import { layoutResolver, shippingIndexResolver } from 'global-configs/page-config';
import { EmbedMixin } from 'global-mixin';

export default {
  name: 'home-page',
  dataResolver: 'editionPage',
  components: {
    FrontpageTopBlock,
    FrontpageMpuBlock,
    EditionRegularBlock,
    LatestNewsTicker,
    FishPriceTicker,
    Placeholder,
  },
  extends: Page,
  mixins: [EmbedMixin],
  data() {
    return {
      ...this.getSlicedArticleList(this.$store.state.edition.articles),
      shippingBlockConfig: shippingIndexResolver(this.$store.state.publication.code),
      shouldShowLatestNews: this.$store.state.publication.shouldShowLatestNews,
      showFishPrice: this.$store.state.publication.showFishPrice,
      search: this.$store.state.publication.menuConfigs.search,
      top: 0,
    };
  },
  beforeMount() {
    this.$root.$on('topBarHeight', (topBarHeight) => { this.top = topBarHeight });
  },
  beforeDestroy() {
    this.$root.$off('topBarHeight');
  },
  mounted() {
    this.pageAutoRefresh();
  },
  methods: {
    pageAutoRefresh() {
      if (this.$pubCtx.autoRefreshMblScript && localStorage.getItem('autoPageRefresh') && localStorage.getItem('autoPageRefresh') === 'true') {
        const script = document.createElement('script');
        script.innerHTML = 'window.mblMeta = window.mblMeta || {}; window.mblMeta.autorefresh = 1;';
        document.head.insertBefore(script, document.head.firstChild);
        localStorage.setItem('autoPageRefresh', 'false');
      } else {
        localStorage.removeItem('autoPageRefresh');
      }

      setTimeout(() => {
        localStorage.setItem('autoPageRefresh', 'true');
        window.location.reload();
      }, 600000);
    },
    getSlicedArticleList(list) {
      const frontPageConfig = layoutResolver(
        this.$store.state.publication.code,
        this.$route.meta.pageContext.type);
      let end = 0;
      return {
        list: frontPageConfig.newsBlocks.map(configItem => {
          const start = end;
          end = start + configItem.numberOfArticles;
          return list.slice(start, end);
        }),
        jobsBlock: frontPageConfig.jobsBlock || null
      };
    },
  },
  embed(head) {
  },
};
</script>
